import React, { useMemo, useState } from 'react';

import RoutesEnum from 'src/enum/RoutesEnum';

import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import AllInboxOutlined from '@material-ui/icons/AllInboxOutlined';
import StarIcon from '@material-ui/icons/StarBorder';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { history } from 'src/App';

import { Sidebar } from './components/sidebar';
import { Navbar } from './components/navbar';

export interface IMenuItem {
  name: string;
  icon: any;
  onClick: () => void;
  page?: string;
  disabled?: boolean;
  admin?: boolean;
  subActions?: any;
  emailsPermissions?: string[]
}

const PermanentDrawerLeft: React.FC = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true);

  const redirectInstance = (route: string) => {
    window.stop();
    history.push(route);
  };

  const goToPage = (page: string) => {
    return page === '' ? null : redirectInstance(page);
  };

  //const emailsPermissions = ['jhemersonlucas@hotmail.com', 'jayro.britto@gmail.com', 'vinicius.augutis@gmail.com', 'guilherme.l@otempo.com.br']

  const itemsMenu: Array<IMenuItem[]> = useMemo(
    () => [
      [
        {
          name: 'Veículos em PDI',
          icon: DashboardOutlinedIcon,
          onClick: () => goToPage(RoutesEnum.DASHBOARD_ROUTE),
          admin: false,
          page: RoutesEnum.DASHBOARD_ROUTE,
        },
        {
          name: 'Veículos em PDI',
          icon: DashboardOutlinedIcon,
          onClick: () => goToPage(RoutesEnum.ADMIN_DASHBOARD_ROUTE),
          admin: true,
          page: RoutesEnum.ADMIN_DASHBOARD_ROUTE,
        },
        {
          name: 'Last Mile Track',
          icon: LocalShippingOutlined,
          onClick: () => goToPage(RoutesEnum.LAST_MILE_TRACK_ROUTE),
          admin: false,
          page: RoutesEnum.LAST_MILE_TRACK_ROUTE,
        },
        {
          name: 'Last Mile Track',
          icon: LocalShippingOutlined,
          onClick: () => goToPage(RoutesEnum.ADMIN_LAST_MILE_TRACK_ROUTE),
          admin: true,
          page: RoutesEnum.ADMIN_LAST_MILE_TRACK_ROUTE,
        },
        /*
        {
          name: 'Solicitações',
          icon: DescriptionOutlinedIcon,
          onClick: () => goToPage(RoutesEnum.REQUEST_PAGE_ROUTE),
          admin: false,
          page: RoutesEnum.REQUEST_PAGE_ROUTE,
          subActions: [{ name: 'Preparação', page: '/requests' }],
        },
        */
        {
          name: 'Solicitações',
          icon: DescriptionOutlinedIcon,
          onClick: () => goToPage(RoutesEnum.REQUEST_PAGE_ADMIN_ROUTE),
          admin: true,
          page: RoutesEnum.REQUEST_PAGE_ADMIN_ROUTE,
        },
        {
          name: 'Estoque',
          icon: AllInboxOutlined,
          onClick: () => goToPage(RoutesEnum.STOCK_PAGE_ROUTE),
          admin: false,
          page: RoutesEnum.STOCK_PAGE_ROUTE,
        },
        {
          name: 'Métricas Operacionais',
          icon: StarIcon,
          onClick: () => goToPage(RoutesEnum.OPERATIONAL_METRICS_PAGE_ADMIN_ROUTE),
          admin: true,
          page: RoutesEnum.OPERATIONAL_METRICS_PAGE_ADMIN_ROUTE          
        },
        {
          name: 'Métricas Operacionais',
          icon: StarIcon,
          onClick: () => goToPage(RoutesEnum.OPERATIONAL_METRICS_PAGE_ROUTE),
          admin: false,
          page: RoutesEnum.OPERATIONAL_METRICS_PAGE_ROUTE
        },
        {
          name: 'Estoque',
          icon: AllInboxOutlined,
          onClick: () => goToPage(RoutesEnum.STOCK_PAGE_ADMIN_ROUTE),
          admin: true,
          page: RoutesEnum.STOCK_PAGE_ADMIN_ROUTE,
        },
        {
          name: 'Clientes',
          icon: GroupOutlinedIcon,
          onClick: () => goToPage(RoutesEnum.VIEW_CLIENT_ROUTE),
          admin: true,
          page: RoutesEnum.VIEW_CLIENT_ROUTE,
        },
        {
          name: 'Equipe',
          icon: SupervisedUserCircleOutlinedIcon,
          onClick: () => goToPage(RoutesEnum.VIEW_TEAM_ROUTE),
          admin: true,
          page: RoutesEnum.VIEW_TEAM_ROUTE,
        },
        /*{
          name: 'Serviços',
          icon: AllInboxIcon,
          onClick: () => goToPage(RoutesEnum.SERVICE_ROUTE),
          admin: true,
          page: RoutesEnum.SERVICE_ROUTE,
        },
        {
          name: 'Serviços',
          icon: BuildIcon,
          onClick: () => goToPage(RoutesEnum.SERVICE_CLIENT_ROUTE),
          admin: false,
          page: RoutesEnum.SERVICE_CLIENT_ROUTE,
        },*/
        {
          name: 'Gerenciar avaliações',
          icon: StarIcon,
          onClick: () => goToPage(RoutesEnum.MANAGE_REVIEWS_ADMIN_ROUTE),
          admin: true,
          page: RoutesEnum.MANAGE_REVIEWS_ADMIN_ROUTE,
        },
        {
          name: 'FAQ',
          icon: InfoIcon,
          onClick: () => goToPage(RoutesEnum.FAQ_ROUTE),
          admin: false,
          page: RoutesEnum.FAQ_ROUTE,
        },
      ]
    ],
    [],
  );

  return (
    <div className="flex h-full">
      <Navbar />
      <Sidebar items={itemsMenu} isOpen={isSidebarOpen} setOpen={setSidebarOpen} />

      <main className="flex flex-1 mt-16 py-6">
        <div className="flex flex-row h-full w-full">
          <div className={`w-full h-full duration-200 ${isSidebarOpen ? 'pl-64' : 'pl-16'}`}>{children}</div>
        </div>
      </main>
    </div>
  );
};

export default PermanentDrawerLeft;
